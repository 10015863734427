import Cookies from 'js-cookie'
import { httpGet, httpPost } from '../utils'

declare global {
    interface Window {
        authenticityToken: string,
        userSignedIn: boolean,
        bookmarks: Set<string>,
        bookmarkButtons: (watchlists: boolean) => void,
        bookmarkProperty: (e: HTMLElement) => boolean,
        buy_bookmarks: Set<string>,
        watchlists: Set<string>,
        watchlistButtons: () => void,
        watchlistBuilding: (e: HTMLElement) => boolean,
        share: (e: HTMLElement) => boolean,
        copyShareUrl: () => void,
        shareSelected: (e: HTMLElement) => boolean,
        compareSelected: (e: HTMLElement) => boolean,
        inquireSelected: (e: HTMLElement) => boolean,
        selectAll: (e: HTMLInputElement) => boolean,
        select: (e: HTMLInputElement) => boolean,
        currency: string,
        rate: number,
        addProratedRent: (e: HTMLInputElement) => boolean
    }
}

window.authenticityToken = document.querySelector('meta[name=csrf-token]')?.getAttribute('content')!
window.userSignedIn = document.querySelector('meta[name=user-signed-in]')?.getAttribute('content')! == 'true'

const toggleBookmarkButton = (e: HTMLElement, propertyId: string) => {
    let bookmarkIcon = e.querySelector('.bookmark-icon')
    if (window.bookmarks.has(propertyId) || window.buy_bookmarks.has(propertyId)) {
        bookmarkIcon?.classList.remove('far')
        bookmarkIcon?.classList.add('fas')
    } else {
        bookmarkIcon?.classList.remove('fas')
        bookmarkIcon?.classList.add('far')
    }
}

const renderBookmarkButtons = () => {
    const bookmarkContainers = document.getElementsByClassName('bookmark-container')
    const bookmarkTemplate = document.getElementById('bookmark-property-template') as HTMLTemplateElement
    for (let i = 0; i < bookmarkContainers.length; i++) {
        bookmarkContainers[i].innerHTML = ''
        let clone = bookmarkTemplate.content.cloneNode(true) as HTMLElement
        const propertyId = bookmarkContainers[i].getAttribute('data-property-id')!
        clone.querySelector('.bookmark-property-button')?.setAttribute('data-property-id', propertyId)
        if (window.userSignedIn) {
            clone.querySelector('.bookmark-icon')?.setAttribute('style', 'color: #00A8C6')
            toggleBookmarkButton(clone, propertyId)
        }
        bookmarkContainers[i].appendChild(clone)
    }
}

window.bookmarkButtons = (watchlist = false) => {
    if (window.userSignedIn) {
        httpGet('/users/properties.json').then((data) => {
            window.bookmarks = new Set(data.bookmarks)
            window.buy_bookmarks = new Set(data.buy_bookmarks)
            renderBookmarkButtons()
            if (watchlist) {
                window.watchlists = new Set(data.watchlists)
                renderWatchlistButtons()
            }
        })
    } else {
        renderBookmarkButtons()
        if (watchlist) {
            renderWatchlistButtons()
        }
    }
}
window.bookmarkProperty = (e: HTMLElement) => {
    const propertyId = e.getAttribute('data-property-id')!
    if (window.userSignedIn) {
        const url = `/users/properties/${propertyId}.json`
        const method = (window.bookmarks.has(propertyId) || window.buy_bookmarks.has(propertyId)) ? 'DELETE' : 'POST'
        httpPost(url, { authenticity_token: window.authenticityToken }, method).then((data) => {
            if (data.property_id) {
                if (data.property_id.startsWith('pr')) {
                    window.buy_bookmarks.has(data.property_id) ? window.buy_bookmarks.delete(data.property_id) : window.buy_bookmarks.add(data.property_id)
                } else {
                    window.bookmarks.has(data.property_id) ? window.bookmarks.delete(data.property_id) : window.bookmarks.add(data.property_id)
                }
                if (e.parentElement && e.parentElement.parentElement) {
                    toggleBookmarkButton(e.parentElement.parentElement, data.property_id)
                }
                if (window.bookmarks.size > 0 || window.buy_bookmarks.size > 0) {
                    $('.favorites-link').css('visibility', 'visible')
                    $('.favorites-count').html(String(window.bookmarks.size + window.buy_bookmarks.size))
                } else {
                    $('.favorites-link').css('visibility', 'hidden')
                    $('.favorites-count').html('0')
                }
            }
        }).catch((error) => {
            if (error.message == 'Not Acceptable') {
                window.alert('You have reached the maximum number of favorites. Please remove some favorites to add more.')
            } else if (error.message == 'Unprocessable Entity') {
                window.alert('Your browser\'s transition may be broken. So please reload the page and try again.')
            }
        })
    } else {
        Cookies.set('bookmark_property_id', propertyId)
        $('#loginModal').modal({})
        $('#loginModal').on('hidden.bs.modal', (e) => {
            Cookies.remove('bookmark_property_id')
        })
    }

    return false
}

const toggleWatchlistButton = (e: HTMLElement, buildingId: string) => {
    let watchlistIcon = e.querySelector('.watchlist-icon')
    if (window.watchlists.has(buildingId)) {
        watchlistIcon?.classList.remove('far')
        watchlistIcon?.classList.add('fas')
    } else {
        watchlistIcon?.classList.remove('fas')
        watchlistIcon?.classList.add('far')
    }
    const watchlistCount = document.getElementById('watchlist-count') as HTMLTemplateElement
    if (watchlistCount) {
        watchlistCount.innerHTML = String(window.watchlists.size)
    }
    const watchlistEmail = document.getElementById('watchlist_email_form') as HTMLTemplateElement
    if (watchlistEmail) {
        if (window.watchlists.size > 0) {
            watchlistEmail.classList.remove('d-none')
        } else {
            watchlistEmail.classList.add('d-none')
        }
    }
}

const renderWatchlistButtons = () => {
    const watchlistContainers = document.getElementsByClassName('watchlist-container')
    const watchlistTemplate = document.getElementById('watchlist-template') as HTMLTemplateElement
    for (let i = 0; i < watchlistContainers.length; i++) {
        watchlistContainers[i].innerHTML = ''
        let clone = watchlistTemplate.content.cloneNode(true) as HTMLElement
        const buildingId = watchlistContainers[i].getAttribute('data-building-id')!
        clone.querySelector('.watchlist-button')?.setAttribute('data-building-id', buildingId)
        if (window.userSignedIn) {
            const watchlistButton = clone.querySelector('.watchlist-btn')
            if (watchlistButton) {
                clone.querySelector('.watchlist-icon')?.setAttribute('style', 'color: #00A8C6')
                toggleWatchlistButton(clone, buildingId)
            }
        }
        watchlistContainers[i].appendChild(clone)
    }
}

window.watchlistButtons = () => {
    if (window.userSignedIn) {
        httpGet('/users/properties.json').then((data) => {
            window.watchlists = new Set(data.watchlists)
            renderWatchlistButtons()
        })
    } else {
        renderWatchlistButtons()
    }
}


window.watchlistBuilding = (e: HTMLElement) => {
    const buildingId = e.getAttribute('data-building-id')!
    if (window.userSignedIn) {
        const url = `/users/properties/${buildingId}.json?watchlist=true`
        const method = window.watchlists.has(buildingId) ? 'DELETE' : 'POST'
        httpPost(url, { authenticity_token: window.authenticityToken }, method).then((data) => {
            if (data.property_id) {
                window.watchlists.has(data.property_id) ? window.watchlists.delete(data.property_id) : window.watchlists.add(data.property_id)
                if (e.parentElement && e.parentElement.parentElement) {
                    toggleWatchlistButton(e.parentElement.parentElement, data.property_id)
                }
            }
        }).catch((error) => {
            if (error.message == 'Not Acceptable') {
                window.alert('You have reached the maximum number of watch buildings. Please remove some buildings to add more.')
            } else if (error.message == 'Unprocessable Entity') {
                window.alert('Your browser\'s transition may be broken. So please reload the page and try again.')
            }
        })
    } else {
        Cookies.set('watchlist_building_id', buildingId)
        $('#loginModal').modal({})
        $('#loginModal').on('hidden.bs.modal', (e) => {
            Cookies.remove('watchlist_building_id')
        })
    }

    return false
}

window.share = (e: HTMLElement) => {
    const url = e.getAttribute('data-url')!
    $('#shareModal').modal({})
    $('#shareUrl').val(url)
    return false
}

window.copyShareUrl = () => {
    navigator.clipboard.writeText($('#shareUrl').val() as string)
    $('#copiedMessage').show()
    setTimeout(() => {
        $('#shareModal').modal('hide')
        $('#copiedMessage').hide()
    }, 3000)
}

const selectedIds = () => {
    let ids: number[] = []
    for (let i = 0; i < $('.favorite-check-property:checked').length; i++) {
        ids.push(Number($('.favorite-check-property:checked')[i].getAttribute('data-id')))
    }
    return ids
}

window.shareSelected = (e: HTMLElement) => {
    const ids = selectedIds()
    $('#loading-icon').show()
    httpPost('/lists', { authenticity_token: window.authenticityToken, properties: ids }, 'POST').then((data) => {
        $('#shareUrl').val(`${location.protocol}//${location.host}/lists/${data.key}`)
        $('#shareModal').modal({})
    }).finally(() => {
        $('#loading-icon').hide()
    })
    return false
}

window.compareSelected = (e: HTMLElement) => {
    const ids = selectedIds()
    $('#loading-icon').show()
    httpPost('/lists', { authenticity_token: window.authenticityToken, properties: ids }, 'POST').then((data) => {
        window.open(`${location.protocol}//${location.host}/lists/${data.key}/compare`, '_top')
    }).finally(() => {
        $('#loading-icon').hide()
    })
    return false
}

window.inquireSelected = (e: HTMLElement) => {
    const ids = selectedIds()
    if (ids.length == 1) {
        $('#selectedNumber').text('1 property selected')
    } else {
        $('#selectedNumber').text(`${ids.length} properties selected`)
    }
    $('#loading-icon').show()
    httpPost('/lists', { authenticity_token: window.authenticityToken, properties: ids }, 'POST').then((data) => {
        $('#list').val(data.key)
        $('#inquireModal').modal({})
    }).finally(() => {
        $('#loading-icon').hide()
    })
    return false
}

window.selectAll = (e: HTMLInputElement) => {
    $('.favorite-check-property').prop({ 'checked': $(e).prop('checked') })
    $('.properties-action').prop({ 'disabled': $('.favorite-check-property:checked').length == 0 })
    return false
}

window.select = (e: HTMLInputElement) => {
    $('.favorite-check-all').prop({ 'checked': $('.favorite-check-property').length == $('.favorite-check-property:checked').length })
    $('.properties-action').prop({ 'disabled': $('.favorite-check-property:checked').length == 0 })
    return false
}

window.addProratedRent = (e: HTMLInputElement) => {
    const dateStr = e.value

    const proratedRentElement = document.getElementById('prorated-rent') as HTMLElement
    let proratedRentValue = 0

    const estInitialCostValueElement = document.getElementById('est-initial-cost-value') as HTMLElement
    let estInitialCostValue = Number(estInitialCostValueElement.getAttribute('data-price')!)

    if (dateStr) {
        const movedInDate = new Date(dateStr)
        const beginingOfMonth = new Date(movedInDate.getFullYear(), movedInDate.getMonth(), 1)
        const endOfMonth = new Date(movedInDate.getFullYear(), movedInDate.getMonth() + 1,)
        const monthDays = Math.round((endOfMonth.getTime() - beginingOfMonth.getTime()) / 1000 / 60 / 60 / 24)
        const diffDays = Math.round((endOfMonth.getTime() - movedInDate.getTime()) / 1000 / 60 / 60 / 24)

        const advanceRent = proratedRentElement.getAttribute('data-advance-rent')!
        const proratedRent = Math.round(Number(advanceRent) / monthDays * diffDays)

        proratedRentValue = proratedRent

        const estInitialCost = estInitialCostValue + proratedRent

        estInitialCostValue = estInitialCost
    }

    if (window.currency !== 'JPY' && window.rate) {
        proratedRentValue = Math.round(proratedRentValue / window.rate)
        estInitialCostValue = Math.round(estInitialCostValue / window.rate)
    }

    proratedRentElement.innerHTML = proratedRentValue.toLocaleString()
    estInitialCostValueElement.innerHTML = estInitialCostValue.toLocaleString()

    return false
}
